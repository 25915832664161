// import './CreateDist.scss'
import { useMutation } from 'urql'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { buildMutationQuery } from '../../client'
import { PlanGuard, SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'
import { useStateContext } from '../../Context/ContextProvider'
import { useEffect } from 'react'
import Select from 'react-select'

const REGIONS = [
  //  'FRANKFURT',
  // 'PARIS',
  // 'LONDON',
  'IRELAND',
  // 'STOCKHOLM',
  // 'MUMBAI',
  // 'MONTREAL',
  'VIRGINIA',
  // 'OHIO',
  // 'SINGAPORE',
  // 'SEOUL',
  // 'TOKYO',
  // 'OREGON',
  // 'SYDNEY',
]

const StorageCreate = () => {
  const navigate = useNavigate()
  const { activeStorage, setActiveStorage } = useStateContext()

  // const [selectedItem, setSelectedItem] = useState('')
  // const [selectActive, setSelectActive] = useState(false)

  // const isTrial = me?.account?.subscription?.type === 'TRIAL'

  const [{ error }, storageCreate] = useMutation(
    buildMutationQuery('storageCreate', ['success', { storage: ['id'] }])
  )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  useEffect(() => {
    register('region', { required: true })
    setValue('region', null)
  }, [setValue, register])

  const onSubmit = async input => {
    const result = await storageCreate({
      input,
    })

    if (result?.data?.storageCreate?.success) {
      navigate(`/storage/${result.data.storageCreate.storage.id}`, { state: { reload: true } })
    }
  }

  const checkKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault()
  }

  return (
    <div className="dashboard__main-wrapper">
      <form
        className="dashboard__main-form"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={checkKeyDown}
      >
        <SubscriptionGuard type="form">
          <PlanGuard>
            <h2 className="content-title">Create New Storage</h2>
            <p className="content-text">FlashEdge storage is...</p>
            <div className="input-wrap">
              <label className="input-label" htmlFor="name">
                Fill the name of your storage [my-storage-1]
              </label>
              <input
                className="input"
                type="text"
                placeholder="Storage name [my-storage-1]"
                name="name"
                id="name"
                required
                {...register('name', { required: true })}
              ></input>
            </div>

            <h3 className="content-subtitle">Select Region</h3>
            <div className="input-wrap select-input">
              <Select
                className="react-select-container"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={false}
                name="region"
                placeholder="Select region"
                options={REGIONS.map(r => ({
                  label: r.substring(0, 1) + r.substring(1).toLocaleLowerCase(),
                  value: r,
                }))}
                onChange={val =>
                  setValue('region', val?.value, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  })
                }
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#f1eded',
                    primary: '#e84f1c',
                  },
                })}
              />
            </div>

            <br />
            <hr />
            <br />
            <p className="content-text">
              You will pay:<br></br>
              <label className="input-label">
                <span className="bold">
                  $0.03 per GB for NA/EU and $0.06 for rest of the regions{' '}
                </span>
                (Data Transfer Out from FlashEdge to the internet)<br></br>
              </label>
              <label className="input-label">
                <span className="bold">$0.0065 per 10.000 requests </span>
                (HTTP and HTTPS)<br></br>
              </label>
              <label className="input-label">
                <span className="bold">
                  $0.02 per GB FlashEdge to origin server{' '}
                </span>
                (Data Transfer Out to Origin)<br></br>
              </label>
              <label className="input-label">
                <span className="bold">$0.005 per invalidation </span>
                (Cache invalidation request)<br></br>
              </label>
            </p>
            {error && (
              <p className={`content-text`}>
                <span className="warning">
                  {error.message.replace('[GraphQL] ', '')}
                </span>
              </p>
            )}
            <button
              className="theme-btn form-btn"
              type="submit"
              disabled={!isDirty || !isValid || isSubmitting}
            >
              {isSubmitting && (
                <svg
                  className="spinner"
                  viewBox="0 0 50 50"
                  width="24"
                  height="24"
                >
                  <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              )}
              Create Storage
            </button>
          </PlanGuard>
        </SubscriptionGuard>
      </form>
    </div>
  )
}

export default StorageCreate
