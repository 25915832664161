import { useStateContext } from '../../Context/ContextProvider'

const roundUp = (num) =>
  num ? parseFloat((Math.ceil(num * 100) / 100).toFixed(2)) : 0


const DistributionUsage = ({ name, usage }) => {
  const { me } = useStateContext()
  const isTrial = me?.account?.subscription?.type === 'TRIAL'
  return (
    <div className="chart-stats">
      <div className="chart-stats__col">
        {!isTrial && (
          <span className="chart-stats__price">
            ${roundUp(usage?.totalPrice)}
          </span>
        )}
        <span className="chart-stats__text">{name}</span>
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">EU & NA</span>
        <span className="chart-stats__text">
          {usage?.euNaDataOut?.usage?.toFixed(3) || 0} GB
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.euNaDataOut?.price)}
          </span>
        )}
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Rest of Regions</span>
        <span className="chart-stats__text">
          {usage?.restDataOut?.usage?.toFixed(3) || 0} GB
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.restDataOut?.price)}
          </span>
        )}
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Data In</span>
        <span className="chart-stats__text">
          {usage?.dataIn?.usage?.toFixed(3) || 0} GB
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.dataIn?.price)}
          </span>
        )}
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Requests</span>
        <span className="chart-stats__text">
          {usage?.requests?.usage || 0}x
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.requests?.price)}
          </span>
        )}
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Invalidations</span>
        <span className="chart-stats__text">
          {usage?.invalidations?.usage || 0}x
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.invalidations?.price)}
          </span>
        )}
      </div>
    </div>
  )
}

export default DistributionUsage
